import React from 'react'
import { PageProps } from 'gatsby'

import Layout from 'src/layouts'
import { Ambassador } from 'src/components/Ambassador'
import SEO from 'src/components/shared/SEO'

const AmbassadorPage: React.FC<PageProps> = ({ location }) => (
  <>
    <SEO title="Friends of Finn" location={location} />

    <Layout location={location}>
      <Ambassador />
    </Layout>
  </>
)

export default AmbassadorPage
